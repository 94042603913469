<template>
  <div class="products-table">
    <h3 class="back-gray" style="margin-top: 50px; margin-bottom: 55px">
      ポイント購入
    </h3>
    <el-tabs type="border-card">
      <el-tab-pane label="ポイントプラン">
        <div class="point-cover">
          <table class="typeA stripe">
            <thead>
              <tr>
                <th class="center">プラン名</th>
                <th class="center">料金（税込）</th>
                <th class="center">付与ポイント</th>
                <th class="center">都度決済での購入</th>
                <!-- <th class="center">自動更新決済での購入</th>
              <th class="center">都度決済での購入</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in productList" :key="index">
                <td class="center">
                  {{ item.planName }}
                </td>
                <td class="center">{{ item.price | localeNum }}円</td>
                <td class="center">
                  {{ item.point | localeNum }}ポイント
                  <div v-show="item.discount !== 0" style="color: red; font-weight: bold">
                    （{{ item.discount | localeNum }}ポイントお得）
                  </div>
                </td>
                <td class="center">
                  <el-button @click="settle(item.productId2)" type="primary">購入</el-button>
                </td>
                <!-- <td class="center">
                <div v-if="activePlanData.length > 0">
                  <div
                    v-if="
                      activePlanData[0].productId === item.productId ||
                      (activePlanData[0].productId === '21' &&
                        item.productId === '19')
                    "
                  >
                    <span>契約中</span>
                    <Question
                      :placement="'right'"
                      :content="'自動更新設定を解除することで契約停止となります。'"
                    />
                  </div>
                  <div v-else>
                    <span style="padding: 0 1.25em">-</span>
                    <Question
                      :placement="'right'"
                      :content="'契約中の自動更新プランを「停止」することで、契約可能となります。'"
                    />
                  </div>
                </div>
                <div v-else @click="settle(item.productId)">
                  <el-button type="primary">購入</el-button>
                </div>
              </td>
              <td style="text-align: center">
                <el-button
                  v-if="item.productId2 !== '20'"
                  @click="settle(item.productId2)"
                  type="primary"
                  >購入</el-button
                >
                <div v-else type="primary">-</div>
              </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div v-if="userData.useAutomaticPayment === true">
      <h4>■自動更新の設定について</h4>
      <p>
        自動更新の設定中のため、ポイント残高が0になる直前に登録中のカードにて決済が執行されます。そのため、ポイント残高不足によるツールの利用ストップがないので安心してお使いいただけます。
      </p>
      <!-- <h4>■自動更新のプラン変更について</h4>
      <p>
        自動更新の設定時にプランを変更したい場合は、一度、契約中の自動更新設定を「停止」してください。その後、新たに契約したいプランの「購入」をクリックし、決済をしてください。<br />
        決済完了によって、新たに契約したプランのポイントが付与されます。<br />
        自動更新による契約時は、それ以降、ポイントの残高がなくなるタイミングで自動更新が行われます。
      </p> -->
    </div>
    <div v-else>
      <h4>■自動更新の設定について</h4>
      <p>
        ポイント残高が不足すると各種ツールの利用がストップしてしまうので、自動更新の設定をオススメします。<br />自動更新の設定中は、ポイント残高が0になる直前に決済が執行されます。そのため、ポイント残高不足によるツールの利用ストップがないので安心してお使いいただけます。
      </p>
    </div>
    <h4>■都度決済での購入</h4>
    <p>
      自動更新の設定を問わず、いつでもしていただくことができます。決済完了後に、購入したポイントが付与されます。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "ProductsTable",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", 'userData', "apiErrorMessage", "productList", "activePlanData"]),
  },
  methods: {
    settle(productId) {
      this.g_buySelectedProduct(productId, "");
    },
  },
};
</script>

<style lang="scss" scoped>
table.typeA {
  tr {
    .border-right {
      border-right: solid 1px #ccc;
    }
  }
}

h4 {
  margin-bottom: 0;
}

.point-cover {
  overflow: auto;

  table {
    width: 1000px;
  }
}
</style>