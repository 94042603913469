<template>
  <div class="bulk-buying">
    <h2>まとめ買い</h2>
    <el-row type="flex">
      <el-col>
        <div class="input-cover">
          <div class="title">個数</div>
          <el-input-number v-model="amount" :min="1" :max="100"></el-input-number>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="productList.length > 0" type="flex" justify="center">
      <el-col :span="8">
        <table class="price-table">
          <tr>
            <th>単価</th>
            <td>{{ productData.priceWithTax | localeNum }}円</td>
          </tr>
          <tr>
            <th>個数</th>
            <td>{{ amount }}</td>
          </tr>
          <tr>
            <th>計</th>
            <td>{{ productData.priceWithTax * amount | localeNum }}円</td>
          </tr>
          <tr>
            <th>付与ポイント</th>
            <td>{{ productData.point * amount | localeNum }}ポイント</td>
          </tr>
        </table>
        <p style="text-align: right;font-size: 12px;">※金額は全て税込み表示です。</p>
      </el-col>
    </el-row>
    <hr>
    <el-row>
      <el-col>
        <div class="button-cover">
          <el-button @click="buy()" type="primary">決済画面へ</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  data() {
    return {
      // 
      productId: '39',
      productList: [],

      // 
      amount: 1
    }
  },
  computed: {
    ...mapGetters(['rt', 'apiErrorMessage']),
    productData() {
      // 
      const productData = this.productList.find(productData => productData.productId === this.productId);

      // 
      return productData;
    },
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      this.$axios
        .get(`${this.rt}/l/api/product/get-list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.productList = response.data.list;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push(`/login`);
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buy() {
      let params = new URLSearchParams();
      params.append("amount", this.amount);
      this.$axios
        .post(`${this.rt}/l/api/payment/buy-in-bulk`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            location.href = response.data.paymentUrl;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            if (response.data.status2 === 1) {
              //no action.
            } else {
              this.$router.push(`/login`);
            }
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.input-cover {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    margin-right: 1em;
  }
}

.price-table {
  margin-top: 2em;
  width: 100%;

  td {
    text-align: right;
  }
}

.button-cover {
  margin-top: 1em;
  text-align: center;
}
</style>